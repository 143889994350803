<template>
	<TitleBar :isChat="true" :title="title" :isBlank="true"
		@goBack="goBack"
		@clearConversation="clearConversation"
		@download="onDownload"
	/>
	<div class="conversation scrollable">
		<ChatBox v-for="item in list" ref="chatBox"
			:key="item[2]" :item="item" :userImg="userImg" :avatarImg="avatarImg"
			@updated="updatePosition" @chooseAIHint="onInput"
		/>
	</div>
	<Inputter ref="inputter" @onInput="onInput" />
	<div class="bottom"></div>
</template>

<style scoped>
.titlebar {
	position: absolute;
	z-index: 1;
	top: 0px;
	width: 100%;
}
.conversation {
	position: absolute;
	z-index: 0;
	top: 64px;
	bottom: 0px;
	left: 0px;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 75px;
}
.bottom {
	position: absolute;
	z-index: 1;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 20px;
	background-color: rgb(49, 51, 57);
	background: linear-gradient(to top, rgba(49, 51, 57, 1.0) 0%, rgba(49, 51, 57, 0.75) 50%, rgba(49, 51, 57, 0.0));
}
</style>

<script>
// @ is an alias to /src
import TitleBar from '@/components/titleBar.vue';
import ChatBox from '@/components/chat.vue';
import Inputter from '@/components/inputter.vue';

const SaveHistory = true;
const AvatarImg = {
	"h1": '/avatar/h1.png',
	"h2": '/avatar/h2.png',
	"s1": '/avatar/s1.png',
	"s2": '/avatar/s2.png',
};

const DuplicateConversation = conversation => {
	var list = conversation.map(item => {
		return [...item];
	});
	return list;
};
const simpleParse = line => {
	line = line.replace(/\*\*([\w\W]+?)\*\*/g, (m, inner) => {
		return '[bold:]' + inner + '[:bold]';
	}).replace(/__([\w\W]+?)__/g, (m, inner) => {
		return '[bold:]' + inner + '[:bold]';
	}).replace(/\*([\w\W]+?)\*/g, (m, inner) => {
		return '[italic:]' + inner + '[:italic]';
	}).replace(/_([\w\W]+?)_/g, (m, inner) => {
		return '[italic:]' + inner + '[:italic]';
	}).replace(/\!\[([\w\W]*?)\]\s*\(([\w\W]+?)\)/g, (m, title, url) => {
		return '{img:' + url + '}';
	}).replace(/\[([\w\W]*?)\]\s*\(([\w\W]+?)\)/g, (m, title, url) => {
		return '<a target="_blank" href="' + url + '">' + title + '</a>';
	});

	return line;
};

var current;

export default {
	name: 'Chat',
	components: {
		TitleBar,
		ChatBox,
		Inputter
	},
	props: ['avatarID'],
	data () {
		return {
			title: '载入中……',
			list: [],
			userImg: '',
			avatarImg: '',
			ego: '',
			soul: '',
			running: 0,
		}
	},
	async mounted () {
		current = this;
		document.title = "Avatar数字化身";

		// 获取自身唯一ID
		var ego = await DataCenter.get(DBNames.record, 'self', 'ego');
		if (!ego) {
			ego = newID(16);
			await DataCenter.set(DBNames.record, 'self', 'ego', ego);
		}
		this.ego = ego;
		console.log(this.ego);

		// 获取当前个人信息的更新记录
		var me = await DataCenter.get(DBNames.record, 'self', 'me');
		if (!me) {
			me = {
				logo: '/avatar/user.png',
				update: 1,
			};
		}
		if (!me.update) me.update = 1;

		this.$refs.inputter.inactive();
		var cid, model;
		try {
			let result = await sendRequest('/avatar', {
				event: 'list',
				ego,
				update: me.update,
			});
			let info = result.avatars[this.avatarID];
			if (!info) {
				notify({
					title: "指定的AI化身不存在",
					message: "你指定的AI化身（" + this.avatarID + "）不存在，请联系管理员确认正确可用的化身ID。",
					duration: 5000,
					type: "error"
				});
				this.title = "离家出走的AI化身：" + this.avatarID
				return;
			}
			this.title = info.name + ' (' + info.AI + ')';
			cid = result.cid;
			model = info.AI;

			if (!!result.need && !!me.name) {
				let ok = await sendRequest('/avatar', {
					event: 'updateUserInfo',
					ego,
					info: me
				});
				if (ok) {
					notify({
						title: "后端已经更新个人信息",
						duration: 5000,
						type: "message"
					});
				}
			}
		}
		catch (err) {
			console.error(err);
			return;
		}

		var color = [];
		color[0] = Math.round(Math.random() * 130) + 70;
		color[1] = Math.round(Math.random() * 130) + 70;
		color[2] = Math.round(Math.random() * 130) + 70;
		if (!!me.logo.match(/^(ht|f)tps?/)) {
			this.userImg = 'background-color: rgb(' + color.join(',') + ');background-image: url("' + me.logo + '")';
		}
		else {
			this.userImg = 'background-color: rgb(' + color.join(',') + ');background-image: url("' + staticHost + me.logo + '")';
		}

		var conversation = await DataCenter.get(DBNames.avatar, 'history', this.avatarID);
		if (!conversation) {
			conversation = localStorage.get('avatar-' + this.avatarID, null);
		}
		if (!conversation) {
			conversation = {};
			conversation.time = Date.now();
			conversation.history = [];
			conversation.id = cid;
			conversation.model = model;
		}
		this.conversation = conversation;
		this.list = DuplicateConversation(this.conversation.history);

		var info = window._avatarList.filter(avatar => avatar.url === this.avatarID)[0];
		var avatarImg = !!info ? staticHost + '/avatar/' + info.image : staticHost + AvatarImg.h1;
		this.avatarImg = 'background-image: url("' + avatarImg + '")'

		this.$refs.inputter.active();
		this.$refs.inputter.focusInput();

		await wait(100);
		this.updatePosition();
	},
	unmounted () {
		if (current === this) current = null;
	},
	methods: {
		goBack () {
			this.$router.push({ path: '/' });
		},
		onInput (content) {
			content = this.parseInput(content);
			if (!content) return;
			this.addChat(0, content);
			this.appendChat(content);
		},
		parseInput (text) {
			var actions = [], emotion, environment;
			// 强制换魂
			text = text.replace(/[\n\r]*\{soul:\s*(.*?)\}[\n\r]*/gi, (m, soul) => {
				this.soul = soul;
				return '\n';
			});
			// 解析场所
			text = text.replace(/[\n\r]*\((场所|environment)\s*[:：]\s*([\w\W]+?)\s*\)[\n\r]*|[\n\r]*（(场所|environment)\s*[:：]\s*([\w\W]+?)\s*）[\n\r]*/gi, (m, u1, env1, u2, env2) => {
				environment = environment || env1 || env2;
				return '\n';
			});
			// 解析动作
			text = text.replace(/[\n\r]*\((动作|action)\s*[:：]\s*([\w\W]+?)\s*\)[\n\r]*|[\n\r]*（(动作|action)\s*[:：]\s*([\w\W]+?)\s*）[\n\r]*/gi, (m, u1, action1, u2, action2) => {
				actions.push(action1 || action2);
				return '\n';
			});
			// 解析情绪
			text = text.replace(/[\n\r]*\((表情|神态|情绪|expressions?|demeanors?|moods?|emotions?)\s*[:：]\s*([\w\W]+?)\s*\)[\n\r]*|[\n\r]*（(表情|神态|情绪|expressions?|demeanors?|moods?|emotions?)\s*[:：]\s*([\w\W]+?)\s*）[\n\r]*/gi, (m, u1, emotion1, u2, emotion2) => {
				emotion = emotion || emotion1 || emotion2;
				return '\n';
			});
			text = text.replace(/^\s*|\s*$/g, '');
			if (actions.length > 0 || !!emotion || !environment) {
				let input = { content: text };
				if (actions.length > 0) {
					input.action = actions.join('\n');
				}
				if (!!emotion) {
					input.emotion = emotion;
				}
				if (!!environment) {
					input.environment = environment;
				}
				return input;
			}
			return text;
		},
		async appendChat (text) {
			this.running ++;
			var rid = this.addChat(4, "正在思考");
			this.removeRunningHint(rid);
			setTimeout(() => {
				this.updatePosition();
			}, 100);
			// this.$refs.inputter.inactive();
			this.$refs.inputter.reset();
			this.$refs.inputter.focusInput();

			try {
				console.log(this.ego);
				let time = Date.now();
				let result = await sendRequest('/avatar', {
					event: 'append',
					avatar: this.avatarID,
					cid: this.conversation.id,
					content: text,
					ego: this.ego,
					soul: this.soul,
				});
				time = Date.now() - time;
				console.log('Time Spent: ' + time + 'ms');
				console.log(result[2] + '(' + result[3] + ')', result[1]);
				if (!!result[0]) {
					console.log(result[0]);
					if (!!result[2].match(/claude/) || !!result[3].match(/claude/)) {
						if (typeof result[0].reply === 'string') result[0].reply = convertClaudeChinese(result[0].reply);
						if (typeof result[0].action === 'string') result[0].action = convertClaudeChinese(result[0].action);
						if (typeof result[0].emotion === 'string') result[0].emotion = convertClaudeChinese(result[0].emotion);
					}
					this.addChat(1, result[0]);
					await wait();
				}
				// this.$refs.inputter.reset();
			}
			catch (err) {
				console.error(err);
				this.addChat(3, err.message || err.msg || err.data || err);
			}

			this.removeRunningHint();
			this.running --;
			if (this.running > 0) {
				this.addChat(4, "正在思考", false, rid);
			}
			// this.$refs.inputter.active();
			// this.$refs.inputter.focusInput();

			await wait(100);
			this.updatePosition();
		},
		addChat (type, content, saveHistory=SaveHistory, rid) {
			var now = Date.now();
			var id = newID(16);
			if (type === 4 && !!rid) id = rid;
			var item = [type, content, id];
			this.list.push(item);
			if (type !== 4) {
				this.conversation.history.push(item);
				this.conversation.time = now;
				if (saveHistory) {
					DataCenter.set(DBNames.avatar, 'history', this.avatarID, this.conversation);
				}
			}
			return id;
		},
		removeRunningHint (rid, exclusive=true) {
			if (!this.$refs.chatBox) return;
			this.$refs.chatBox.forEach((box) => {
				if (!box.isRunning) return;
				if ((box.index === rid) === exclusive) return;
				box.$el.style.display = 'none';
				box.isRunning = false;
			});
		},
		updatePosition () {
			if (!this.$refs.chatBox || !this.$refs.chatBox.length) return;
			this.$refs.chatBox.forEach(box => {
				if (box.$el._isRunningHint) {
					box.$el.parentElement.appendChild(box.$el);
				}
			});
			var last = this.$refs.chatBox[this.$refs.chatBox.length - 1];
			last.$el.scrollIntoView();
		},
		async clearConversation () {
			console.log('Clear Conversation');

			var success;
			try {
				success = await sendRequest('/avatar', {
					event: 'reset',
					cid: this.conversation.id
				});
				if (!success) {
					notify({
						title: "聊天记录本就为空",
						duration: 5000,
						type: "warn"
					});
					return;
				}
			}
			catch (err) {
				console.error(err);
				notify({
					title: "聊天记录清空失败",
					message: "请稍后重试，或者联系管理员。",
					duration: 5000,
					type: "failed"
				});
				return;
			}

			this.conversation.history.splice(0);
			this.conversation.time = Date.now();
			this.list = [];
			if (SaveHistory) {
				await DataCenter.set(DBNames.avatar, 'history', this.avatarID, this.conversation);
				localStorage.removeItem('avatar-' + this.avatarID);
			}

			notify({
				title: "聊天记录已清空",
				message: "本数字分身已被清空记忆，您可以重新开始新的聊天。",
				duration: 5000,
				type: "success"
			});
		},
		async onDownload () {
			var name = this.title.replace(/\s*[\(（][\w\W]+[\)）]\s*/gi, '');
			var model = this.title.replace(name, '').replace(/^\s*|\s*[\(\)（）]\s*|\s*$/gi, '');

			var inner = ['[title:]History of the conversation with ' + name + '[:title]'];
			var markdown = ["#\tHistory of the conversation with " + name];
			var filename = getTimeString(this.conversation.time);
			markdown.push('> Soul Model: ' + model + '\n> Updated at: ' + filename);
			inner.push('[center:]{block:start|w200}Soul Model: ' + model + '{block:end}[:center]\n[center:]{block:start|w200}Updated at: ' + filename + '{block:end}[:center]');
			markdown.push('\n-----\n');
			inner.push('-----');
			filename = this.avatarID + '-' + filename.replace(/ /g, '-').replace(/\//g, '') + '.hst';

			var inSide = -1;
			this.conversation.history.forEach(item => {
				let ctx = '', inn = [];
				if (item[0] === 0) {
					let str;
					if (inSide !== 0) {
						markdown.push('##\tMe');
						inner.push('[right:][big:][bold:]Me[:bold][:big][:right]');
					}
					str = item[2] * 1;
					if (!isNaN(str) && str !== 0) {
						str = getTimeString(item[2]);
						ctx = '(' + str + ')\n';
						inn.push('[right:][italic:](' + str + ')[:italic][:right]');
					}
					str = item[1].replace(/^\s+|\s+$/g, '').replace(/\n{3,}/g, '\n\n');
					ctx = ctx + str;
					str = str.split('\n').map(l => '[right:]' + simpleParse(l) + '[:right]').join('\n');
					inn.push(str);
					inSide = 0;
				}
				else if (item[0] === 1) {
					let str;
					if (inSide !== 1) {
						markdown.push('##\t' + name);
						inner.push('[big:][bold:]' + name + '[:bold][:big]');
					}
					str = item[2] * 1;
					if (!isNaN(str) && str !== 0) {
						str = getTimeString(item[2]);
						ctx = '(' + str + ')\n';
						inn.push('[italic:](' + str + ')[:italic]');
					}
					str = item[1].replace(/^\s+|\s+$/g, '').replace(/\n{3,}/g, '\n\n');
					ctx = ctx + str;
					str = str.split('\n').map(l => simpleParse(l)).join('\n');
					inn.push(str);
					inSide = 1;
				}
				if (!!ctx) {
					markdown.push(ctx);
					inner.push(inn.join('\n'));
				}
			});
			markdown = markdown.join('\n\n');
			inner = inner.join('\n\n');
			inner = inner.split('\n').map(l => {
				if (!!l) return l;
				return '{block:start|h20}{block:end}';
			}).join('\n');

			var blob = new Blob([markdown], { type: 'text/plain' });
			var link = URL.createObjectURL(blob);
			var downloader = newEle('a');
			downloader.setAttribute('href', link);
			downloader.setAttribute('download', 'chatHistory.md');
			downloader.click();

			if (isExperiment) {
				await DataCenter.set(DBNames.record, 'avatar', filename, inner);
				notify({
					title: "聊天记录已经保存到本地",
					message: "你也可以在console中/SAIDAO/History/目录下查看边缘端记录。",
					duration: 5000,
					type: "success"
				});
			}
			else {
				notify({
					title: "聊天记录已经保存到本地",
					duration: 3000,
					type: "success"
				});
			}
		},
	}
};
</script>